'use strict'

import {onFind} from "@elements/init-modules-in-scope";
import {toggleSearch} from "./search-mobile";
import * as sideNav from "./sidenav";
import {findAll, findIn} from "@elements/dom-utils";
import formDataEntries from 'form-data-entries';



export function init() {

   const
            $sideNav        = findAll('.js-sidenav')[0], //can only be one
            $target         = findIn('.js-sidenav__link--request', $sideNav),
            $submitBtn      = document.querySelectorAll('.js-hero-filter__submit')[0],
            $form           = document.querySelectorAll('.js-hero-filter')[0];

   onFind('.js-hero-filter', async function ($form) {

       let sideNavInstance = await sideNav.getApi($sideNav);
       openSideBar($form, $sideNav, sideNavInstance, $target, $submitBtn);

       onFind('.js-hero-filter__submit', async function () {
           let sideNavInstance = await sideNav.getApi($sideNav);
           openSideBar($form, $sideNav, sideNavInstance, $target, $submitBtn);
       });
    });
}

function openSideBar($form, $sideNav, sideNavInstance, $target, $submitBtn) {
    $submitBtn = document.querySelectorAll('.js-hero-filter__submit')[0];
    $form = document.querySelectorAll('.js-hero-filter')[0];
    $submitBtn.addEventListener('click', (e) => {
        e.preventDefault();
        sideNavInstance.loadSidebar($target, formDataEntries($form))
    });
}