'use strict'
import initModulesInScope, {onFind} from "@elements/init-modules-in-scope";
import { createLightbox } from "@elements/lightbox";
import { on, findIn } from "@elements/dom-utils";
import fetch from "@elements/fetch";
import asyncAppend from "@elements/async-append";
import {getLoadingContainer, removeLoadingContainer} from "./loading";
import {createHtmlElement} from "./helper";
import {lockScroll, unlockScroll} from "./lock-scroll";

export function init() {
    onFind('.js-gallery-area', initGallery)
}

export function initGallery($baseElement) {
    const
        $lightbox   = findIn('.js-lightbox', $baseElement),
        $moreBtn    = findIn('.js-lightbox__load-more', $baseElement),
        queryUrl    = $baseElement.getAttribute('data-gallery-load-more-url'),
        $loading    = getLoadingContainer({overlay: true, small: true });

    let pendingRequest  = null,
        lightbox        = null,
        imgRight        = true,
        page            = 0;

   createLightbox($lightbox).then(() => {
       lightbox = window.lgData[$lightbox.getAttribute('lg-uid')]
       if($moreBtn){
           on('click', loadMoreItems, $moreBtn)
       }
   });


    function loadMoreItems (event) {
        event.preventDefault();
        $moreBtn.appendChild($loading);


        let body = new URLSearchParams();
        body.append("imgRight", (imgRight ? 1 : 0));
        body.append("page", ++page);

        pendingRequest = fetch(queryUrl, {
            body: body
        }).then((res) => {
            return res.clone().json()
        }).then((res) => {

            pendingRequest = null;
            imgRight = !imgRight;

            const items = Array.from(createHtmlElement("<span>" + res.html + "</span>").childNodes).filter( (node) => node.nodeType !== Node.TEXT_NODE);
            let item;

            items.forEach( function (item) {
                $lightbox.appendChild(item);
                initModulesInScope(item);
                console.log(item.getAttribute('data-loaded-all'));
                if(item.getAttribute('data-loaded-all').length > 0){
                    $moreBtn.classList.add('d-none');
                }
            });
            // same as above. iterator deletes item when re-assigning it
            // for(let [i, item] of items.childNodes.entries()){
            //     const element = item.cloneNode(true);
            //     console.log("item", element);
            //     $lightbox.appendChild(element);
            //     initModulesInScope(element);
            // }
            //
            try{
                lockScroll(); // stupid fix because of: https://github.com/sachinchoolur/lightgallery.js/issues/78
                lightbox.destroy(true);
                lightbox = null;
                unlockScroll();
                createLightbox($lightbox).then(() => {
                    lightbox = window.lgData[$lightbox.getAttribute('lg-uid')]
                })

            } catch{
                console.error("light gallery not reinitialized")
            }

            removeLoadingContainer($moreBtn);
        }).catch((error) => {
            console.error(error);
            pendingRequest = null;
            removeLoadingContainer($moreBtn)
        })
    }
}