import { onFind } from "@elements/init-modules-in-scope"

import Modal from 'bootstrap.native/dist/components/modal-native.esm';
onFind('[data-toggle="modal"]', function (element) {
    new Modal(element);
});

import Dropdown from 'bootstrap.native/dist/components/dropdown-native.esm';
onFind('[data-toggle="dropdown"]', function (element) {
    new Dropdown(element);
});

import Toast from 'bootstrap.native/dist/components/dropdown-native.esm';
onFind('[data-dismiss="toast"]', function (element) {
    new Toast(element.parentNode);
});

import Alert from 'bootstrap.native/dist/components/alert-native.esm';
onFind('[data-dismiss="alert"]', function (element) {
    new Alert(element);
});

import Collapse from 'bootstrap.native/dist/components/collapse-native.esm';
onFind('[data-toggle="collapse"]', function (element) {
    new Collapse(element);
});

import Tab from 'bootstrap.native/dist/components/tab-native.esm';
onFind('[data-toggle="tab"]', function (element) {
    new Tab(element, {height: element.getAttribute('data-tab-height') || false } );
})

import Tooltip from 'bootstrap.native/dist/components/tooltip-native.esm';
onFind('[data-toggle="tooltip"],[data-tip="tooltip"]', function (element) {
    new Tooltip(element);
})



if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};

window.app = app || {};
window.app.modules =  app.modules || {};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
toc.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init({preventNative: true});

import * as lightbox from '@elements/lightbox';
app.lightbox = lightbox.init;

import * as floatingLabel from '@elements/floating-labels';
app.floatingLabel = floatingLabel.init;

import * as sidenav from './scripts/sidenav';
app.sidenav = sidenav.init;

import * as ajaxForm from '@elements/ajax-form';
app.ajaxForm = ajaxForm.init;

import * as heroFilter from './scripts/heroFilter';
app.heroFilter = heroFilter.init;

import * as openContact from './scripts/open-contact';
app.openContact = openContact.init;

import * as productRequestSidebar from './scripts/product-request-sidebar';
app.productRequestSidebar = productRequestSidebar.init;

import * as productDetailRequestSidebar from './scripts/product-detail-request-sidebar';
app.productDetailRequestSidebar = productDetailRequestSidebar.init;

import * as sidenavSearch from './scripts/sidenav-search';
app.sidenavSearch = sidenavSearch.init;

import * as scrollObserver from './scripts/lock-scroll';
app.scrollObserver = scrollObserver.init;

import * as formValidation from './scripts/form-validation';
app.formValidation = formValidation.init;

import * as fileInput from '@elements/file-input';
app.fileInput = fileInput.init;

import * as lazyIframe from '@elements/lazy-iframe';
app.lazyIframe = lazyIframe.init;

import * as gallery from './scripts/gallery';
app.gallery = gallery.init;

import * as header from './scripts/header';
app.header = header.init;

import * as hideEmptyNav from './scripts/hide-empty-nav';
app.hideEmptyNav = hideEmptyNav.init;

import * as searchMobile from './scripts/search-mobile';
app.searchMobile = searchMobile.init;

import * as video from './scripts/video';
app.video = video.init;

import * as locationSVG from './scripts/location-SVG';
app.locationSVG = locationSVG.init;

import * as affix from '@elements/affix';
app.affix = affix.init;

import * as smoothScroll from "@elements/smooth-scroll";
smoothScroll.init();

import * as anchorNav from "@elements/anchor-nav";
anchorNav.init(
    {
        renderItem: function (text, anchor) {
            return '<li class="nav-item anchor-nav__item js-anchor-nav__item list-inline-item"><a class="anchor-nav__link js-smoothscroll__link" data-scroll-offset="150" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
        },
        smoothScroll: true,
        scrollSpy: true,
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset: 200
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);



import { init } from "@elements/cookie-permissions/boostrap-4-native";
if (!_config.admin && !_config.editmode) {
    init();
}

Object.keys(_config).forEach(function (element, index) {
    if( ( typeof _config[element] == 'boolean' && typeof window.app[element] == 'function' && element !== '_reload' && element !== '_preload' ) ){
        window.app[element]();
    }
});