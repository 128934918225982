'use strict'
import * as sideNav from "./sidenav"

export const INITIALIZED_EVENT = 'search/initialized';
import { findAll, findIn, trigger, addClass, removeClass, hasClass, on, off } from "@elements/dom-utils";
import {throttle} from "throttle-debounce";

export function init() {
    const
        $sidenavSearch = findAll('.js-sidenav-search');

    $sidenavSearch.map(($baseElement) => initSearch($baseElement)) //"should" just be one
}

async function initSearch($baseElement) {
    const
        $sideNav        = findAll('.js-sidenav')[0], //can only be one
        sideNavInstance = await sideNav.getApi($sideNav),
        $searchForm     = findIn('.js-sidenav-search__form', $baseElement),
        $searchContainer= findIn('.js-sidenav-search__input-container', $baseElement),
        $searchInput    = findIn('.js-sidenav-search__input', $baseElement),
        $searchCloseBtn = findIn('.js-sidenav-search__btn-close', $baseElement),
        $searchBtn      = findIn('.js-sidenav-search__btn', $baseElement)

    let isOpen = false;

    function scrollListener(){
        if(window.matchMedia('(max-width: 1250px)').matches || window.scrollY > 50){
            $baseElement.classList.remove('is-top');
            if($searchInput !== document.activeElement){
                closeSearch();
            }
        }else {
            if (!document.lockScroll) {
                $baseElement.classList.add('is-top');
                openSearch();
            }
        }
    }

    scrollListener();
    window.addEventListener('scroll', throttle(100, scrollListener));
    window.addEventListener('resize', throttle(100, scrollListener));

    $searchBtn.addEventListener('click', () => {
       if(isOpen){
           if($searchInput.value){
               $searchForm.submit()
           }
       } else{
           openSearch();
       }
    });

    $searchCloseBtn.addEventListener('click', () => {
        closeSearch();
    })

    function closeSearch() {
        $searchContainer.classList.remove('is-active');
        isOpen = false;
    }

    function openSearch(){
        if(sideNavInstance.isOpen()){
            sideNavInstance.closeSidebar();
        }
        $searchContainer.classList.add('is-active')
        isOpen = true;
    }

    $baseElement.search = {
        forceCheck : scrollListener,
        openSearch,
        closeSearch,
        isOpen : () => {
            return isOpen
        }
    };
    trigger(INITIALIZED_EVENT, $baseElement);
}

export function getApi(element) {
    if(element.search) {
        return Promise.resolve(element.search);
    } else {
        return new Promise(function(resolve, reject) {
            function initializeHandler() {
                resolve(element.search);
                off(INITIALIZED_EVENT, initializeHandler, element);
            }
            on(INITIALIZED_EVENT, initializeHandler, element);
        });
    }
}
