import Popover from 'bootstrap.native/dist/components/popover-native.esm';
import { onFind } from "@elements/init-modules-in-scope"
import { findAll } from "@elements/dom-utils";

const popovers = new Array();

export function init() {

    onFind('[data-toggle="popover"]', function (popover) {
        var popoverClass = popover.getAttribute('data-position') === 'fixed' ? 'location-svg__popover--fixed' : '';
        new Popover(popover, {
            "trigger" : "focus",
            "template" : "<div class='location-svg__popover " +
                popoverClass +
                " popover' role='tooltip' >" +
                    "<button type='button' class='btn btn-no-styling location-svg__close js-location-svg__close'><i class='icon icon-close'></i></button>" +
                    "<div class='arrow d-none'></div>" +
                    "<div class='popover-header'></div>" +
                    "<div class='popover-body'></div>" +
                "</div>"
        });

        popover.addEventListener('show.bs.popover', function(){
            popover.classList.add('active');
            popovers.forEach((pop) => {
                if(pop !== popover){
                    pop.Popover.hide();
                    pop.classList.remove('active');
                }
            })
        }, false);

        popover.addEventListener('shown.bs.popover', function(){
            findAll('.js-location-svg__close').forEach( (element) => {
                element.addEventListener('click', () => {
                    popover.Popover.hide();
                    popover.classList.remove('active');
                })
            })
        }, false);

        popovers.push(popover);
    })
}