'use strict'

import {onFind} from "@elements/init-modules-in-scope";
import * as sideNav from "./sidenav";
import {findIn} from "@elements/dom-utils";

export function init() {

    const $sideNav = document.querySelectorAll('.js-sidenav')[0],
          $target  = findIn('.js-sidenav__link--request', $sideNav);

    onFind('.js-product-request', async function (e) {
        let sideNavInstance = await sideNav.getApi($sideNav);
        openSidebar($sideNav, sideNavInstance, $target);

        onFind('.js-open-nav', async function () {
            sideNavInstance = await sideNav.getApi($sideNav);
            openSidebar($sideNav, sideNavInstance, $target);
        },e);
    });
}

function openSidebar($sideNav, sideNavInstance, $target){
    let $submitButtons  = document.querySelectorAll('.js-open-nav');
    $submitButtons.forEach( function(item) {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            let entries = constructFormData(item);
            sideNavInstance.loadSidebar($target, entries);
        });
    });
}

export function constructFormData(item){
    let productName = item.getAttribute('data-product-name');
    let productCategory = item.getAttribute('data-product-category');
    let productBuy = item.getAttribute('data-product-buy');
    let entries = new FormData();
    entries.append('category', productCategory);
    entries.append('product', productName);
    entries.append('rentOrBuy', productBuy);
    return entries;
}