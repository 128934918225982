'use strict'
import {throttle} from "throttle-debounce";

export function init(){
    // save scroll pos for removing scrollbar when modal opend (ios .modal-open fix)
    function scrollListener(){
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    }
    scrollListener();
    window.addEventListener('scroll', throttle(100, scrollListener));
}

export function lockScroll() {
    //save scroll pos and remove default scrollbar (for ios)
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.width = '100%';
    body.style.top = `-${scrollY}`;
    document.lockScroll = true;
}

export function unlockScroll() {
    //set back to original scroll position
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    document.lockScroll = false;
}
