'use strict'

import {findAll, findIn, findAllIn, addClass, removeClass, hasClass, on, off} from "@elements/dom-utils";
import {lockScroll, unlockScroll} from "./lock-scroll";


const breakPoint = window.matchMedia('(max-width: 767px)');


export function init() {
    const
        $sideNav = findAll('.js-header');

    $sideNav.map(($baseElement) => initHeader($baseElement));
}

function initHeader($baseElement) {

    /** bind mobile / desktop nav switches on matchmedia events **/
    if (document.documentMode || /Edge/.test(navigator.userAgent) || (/iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.userAgent.indexOf("Safari") !== -1 && !window.MSStream)) {
        breakPoint.addListener((e) => resizeHandler(e)); //ie fallback
    } else {
        breakPoint.addEventListener("change", resizeHandler);
    }

    const elements = {
        header : $baseElement,
        navItems : findAllIn('.js-header__nav-item', $baseElement),
        navItemsMain : findAllIn('.js-header__nav-item-main', $baseElement),
        navItemsSub : findAllIn('.js-header__nav-item-sub', $baseElement),

        toggleMobieNav: findIn('.js-header__nav-mobile-toggle', $baseElement),
        mobileNavGoBack: findAllIn('.js-header__nav-item-goback', $baseElement),
        mobileNavGoBackSub: findAllIn('.js-header__nav-item-goback-sub', $baseElement),

        headerContainer: findIn('.js-header__nav--container', $baseElement),
        searchContainer: findAll('.js-sidenav-search')[0],
        allListItems: findAllIn('.js-header__nav-item-li', $baseElement),
        allSubListItems: findAllIn('.js-header__nav-item-sub-li', $baseElement),

        headerBrand: findAllIn('.js-header-brand', $baseElement),
        subSubNavContainers: findAllIn('.js-header__subsubnav', $baseElement),
        subItemsWithoutSubItems: findAllIn('.js-header__nav-item-sub-li', $baseElement).filter( item => !item.classList.contains('has-subnav'))
    };
    const desktopNav = new DesktopNavigation(elements);
    const mobileNav = new MobileNavigation(elements);

    function resizeHandler(e) {
        if(e.matches){
            desktopNav.destroy();
            mobileNav.init();
        }else{
            mobileNav.destroy();
            desktopNav.init();
        }
    }

    /** init Navigation **/
    if(breakPoint.matches){
        mobileNav.init();
    }else{
        desktopNav.init();
    }
}


function DesktopNavigation(elements) {
    let
        time = 150,
        timeout = null;

    function closeNavigation(force = false) {
        clearTimeout(timeout);
        if(typeof force !== 'object' && force === true){
            elements.navItemsMain.forEach((navItemsMain) => {
                navItemsMain.classList.remove('is-active');
            });
            elements.header.classList.remove('is-active');
            elements.searchContainer.classList.remove('is-menu-active');
            return
        }
        timeout = setTimeout(function() {
            elements.navItemsMain.forEach((navItemsMain) => {
                navItemsMain.classList.remove('is-active');
            });
            elements.header.classList.remove('is-active');
            swapLogoMain(elements.headerBrand[0]);
            elements.searchContainer.classList.remove('is-menu-active');
        }, time);
    }

    function openNavigation($target) {
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            closeNavigation(true)
            $target.classList.add('is-active');
            elements.header.classList.add('is-active');
            swapLogoWhite(elements.headerBrand[0]);
            elements.searchContainer.classList.add('is-menu-active');
        }, time)
    }

    function closeSubNav (event) {
        const $link = event.target;
        const $listItem = $link.closest('.js-header__nav-item-sub-li');
        elements.allSubListItems.forEach((subNavListItem) => {
            subNavListItem.classList.remove('is-active')
        })
        $listItem.classList.remove('is-active');
        //resetSubNavHeight($listItem);
    }

    function resetSubNavHeight($listItem){
        $listItem.closest('.js-header__dropdown-list').style.minHeight = 0;
    }

    function swapLogoWhite (obj) {
        let logoHover = obj.dataset.logoHover;
            obj.setAttribute('src',logoHover)
    }

    function swapLogoMain (obj) {
        let logo = obj.dataset.logo;
        obj.setAttribute('src',logo)
    }

    function removeEventListeners() {
        ['mouseenter', 'touchend', 'focus'].forEach(function(etype) {
            elements.navItemsMain.forEach((navItemMain) => {
                navItemMain.removeEventListener(etype, openNavItemMain);
            })
            elements.navItemsSub.forEach((navItemsSub) => {
                navItemsSub.removeEventListener(etype, showSubnavItem);
            })
        });

        ['mouseleave', 'blur'].forEach(function(etype) {
            elements.navItemsMain.forEach((navItemMain) => {
                navItemMain.removeEventListener(etype, closeNavigation);
            });
            elements.subSubNavContainers.forEach((subSubNavContainer) => {
                subSubNavContainer.removeEventListener(etype, closeSubNav);
            });
        });
    }



    function openNavItemMain(event) {
        const $link = event.target;
        const $subnav = $link.querySelector($link.firstElementChild.getAttribute('data-controls'));

        // no sub nav present - ignore and close all others (edit: removed so all items behave the same when hovered)
        //if(!$subnav){
        //    closeNavigation();
        //    return;
        //}

        //if touch is on same target close nav
        if(event.currentTarget.parameters.etype === 'touchend' && hasClass("is-active", $subnav)){
            $link.classList.remove('is-active');
            return;
        }

        // closeNavigation();
        openNavigation($link);
    }

    function showSubnavItem(event) {
        const $link = event.target;
        const $listItem = $link.closest('.js-header__nav-item-sub-li');

        elements.allSubListItems.forEach((subNavListItem) => {
            subNavListItem.classList.remove('is-active')
        })

        $listItem.classList.add('is-active');
        setSubNavHeight($listItem);
    }

    function setSubNavHeight($listItem){
        const $link = $listItem.querySelector('.js-header__nav-item');
        const $subnav = $listItem.querySelector($link.getAttribute('data-controls'));
        const height = $subnav.getBoundingClientRect().height;

        $listItem.closest('.js-header__dropdown-list').style.minHeight = height + "px";

    }

    this.init = function () {

        ['mouseenter', 'touchend', 'focus'].forEach(function(etype) {
            //bind main nav events
            elements.navItemsMain.forEach((navItemMain) => {
                navItemMain.parameters = {
                    etype : etype
                };
                navItemMain.addEventListener(etype, openNavItemMain);
            });

            //bind sub nav events
            elements.navItemsSub.forEach((navItemsSub) => {
                navItemsSub.parameters = {
                    etype : etype
                };
                navItemsSub.addEventListener(etype, showSubnavItem);
            });

            elements.subItemsWithoutSubItems.forEach((navItemsSubWithoutSub) => {
                navItemsSubWithoutSub.parameters = {
                    etype : etype
                };
                navItemsSubWithoutSub.addEventListener(etype, closeSubNav);
            });

        });

        ['mouseleave', 'blur'].forEach(function(etype) {
            elements.navItemsMain.forEach((navItemMain) => {
                navItemMain.addEventListener(etype, closeNavigation);
            });
            elements.subSubNavContainers.forEach((subSubNavContainer) => {
                subSubNavContainer.addEventListener(etype, closeSubNav);
            });
        });
    }


    this.destroy = function () {
        closeNavigation();
        removeEventListeners()
    }


}



function MobileNavigation(elements) {

    function toggleMobileNav() {
        if(elements.headerContainer.classList.contains('is-active')){
            elements.headerContainer.classList.remove('is-active');
            closeAllSubnavs();
            unlockScroll();

        }else{
            elements.headerContainer.classList.add('is-active');
            lockScroll();
        }
    }

    function openSubnav(event) {
        const $link = event.target;
        const $listItem = $link.closest('.js-header__nav-item-li');
        const $subnav = $listItem.querySelector($link.getAttribute('data-controls'));

        if(!$subnav){
            return;
        }

        event.preventDefault();

        $listItem.classList.add('is-active');
        $subnav.querySelector(".js-header__nav-item-goback").focus();
    }

    function closeSubnav(event) {
        const $link = event.target;
        const $listItem = $link.closest('.js-header__nav-item-li')

        $listItem.classList.remove('is-active');
        $listItem.querySelector('a').focus();
    }

    function closeAllSubnavs() {
        elements.allListItems.forEach((listItem) => {
            listItem.classList.remove('is-active');
        })
    }

    function openSubSubnav(event) {
        const $link = event.target;
        const $listItem = $link.closest('.js-header__nav-item-sub-li');
        const $subnav = $listItem.querySelector($link.getAttribute('data-controls'));

        if(!$subnav){
            return;
        }

        event.preventDefault();

        $listItem.classList.add('is-active');
        $subnav.querySelector(".js-header__nav-item-goback").focus();
    }

    function closeSubSubnav(event) {
        const $link = event.target;
        const $listItem = $link.closest('.js-header__nav-item-sub-li')

        $listItem.classList.remove('is-active');
        $listItem.querySelector('a').focus();
    }

    function closeAllSubSubnavs() {
        elements.allSubListItems.forEach((listItem) => {
            listItem.classList.remove('is-active');
        })
    }
    this.init = function(){
        elements.toggleMobieNav.addEventListener('click', toggleMobileNav);
        elements.navItems.forEach((navItem) => {
            navItem.addEventListener('click', openSubnav);
        })
        elements.mobileNavGoBack.forEach((mobileNavGoBack) => {
            mobileNavGoBack.addEventListener('click', closeSubnav);
        });
        elements.mobileNavGoBackSub.forEach((mobileNavGoBack) => {
            mobileNavGoBack.addEventListener('click', closeSubSubnav);
        });
        elements.navItemsSub.forEach((navItemsSub) => {
            navItemsSub.addEventListener('click', openSubSubnav)
        })
    }


    this.destroy = function () {
        unlockScroll();
        closeAllSubnavs();
        closeAllSubSubnavs();
        elements.toggleMobieNav.removeEventListener('click', toggleMobileNav);
        elements.navItems.forEach((navItem) => {
            navItem.removeEventListener('click', openSubnav);
        });
        elements.mobileNavGoBack.forEach((mobileNavGoBack) => {
            mobileNavGoBack.removeEventListener('click', closeSubnav)
        });
        elements.mobileNavGoBackSub.forEach((mobileNavGoBack) => {
            mobileNavGoBack.removeEventListener('click', closeSubSubnav);
        });
        elements.navItemsSub.forEach((navItemsSub) => {
            navItemsSub.removeEventListener('click', openSubSubnav)
        });

    }

}

