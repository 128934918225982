'use strict'

import {onFind} from "@elements/init-modules-in-scope";
import * as sideNav from "./sidenav";
import { constructFormData } from "./product-request-sidebar";
import {findIn} from "@elements/dom-utils";

export function init() {

    const $sideNav = document.querySelectorAll('.js-sidenav')[0],
        $target  = findIn('.js-sidenav__link--request', $sideNav);

    onFind('.js-product-detail-request', async function (item) {
        let sideNavInstance = await sideNav.getApi($sideNav);
        item.addEventListener('click', (e) => {
            e.preventDefault();
            let entries = constructFormData(item);
            sideNavInstance.loadSidebar($target, entries);
        });
    });
}
