'use strict'

import {onFind} from "@elements/init-modules-in-scope";
import {findAll} from "@elements/dom-utils";
import {throttle} from "throttle-debounce";

window.states = {
    ...window.states,
    searchOpen : false
};

export function init() {
    onFind('.js-header__search-mobile-toggle', function (button) {
        button.addEventListener('click', toggleSearch)
    });

    window.addEventListener('resize', throttle(100, resizeListener));

    function resizeListener(){
        if(window.states.searchOpen && window.matchMedia('(min-width: 767px)').matches){
            closeSearch();
        }
    }
}

export function toggleSearch() {
    if(window.states.searchOpen){
        closeSearch()
    }
    else{
        openSearch();
    }
}

export function openSearch() {
    const $searchContainer  = findAll('.js-header__search-mobile-container')[0];
    console.log("$searchContainer",$searchContainer)
    $searchContainer.classList.add('is-active');
    window.states.searchOpen = true;
}

export function closeSearch() {
    const $searchContainer  = findAll('.js-header__search-mobile-container')[0];
    $searchContainer.classList.remove('is-active');
    window.states.searchOpen = false;
}