'use strict'
import { lockScroll, unlockScroll } from "./lock-scroll";
import { findAll, findAllIn, findIn, on, off, addClass, removeClass, hasClass, trigger } from "@elements/dom-utils";
import fetch from "@elements/fetch";
import {getLoadingContainer, removeLoadingContainer} from "./loading";
import asyncAppend from "@elements/async-append"
import * as sidenavSearch from "./sidenav-search"
import 'url-search-params-polyfill'; // Edge Polyfill

export const INITIALIZED_EVENT = 'sidenav/initialized';

export function init() {
    const
        $sideNav = findAll('.js-sidenav');

    $sideNav.map(($baseElement) => initSidebar($baseElement))
}

function initSidebar($baseElement){
    const
        $links      = findAllIn('.js-sidenav__link', $baseElement),
        $search     = findIn('.js-sidenav-search', $baseElement),
        searchApi   = sidenavSearch.getApi($search),
        $overlay    = findIn('.js-sidenav__overlay', $baseElement),
        $container  = findIn('.js-sidenav__container', $baseElement),
        $loading    = getLoadingContainer({overlay: true });

    let isOpen = false, pendingRequest;

    $links.map(on('click', (event) => {
        event.preventDefault();
        if(pendingRequest){
            return;
        }

        if(hasClass('is-active', event.target)){
            closeSidebar();
        }else{
            loadSidebar(event.target);
        }
    }));

    function closeSidebar() {
        if(pendingRequest){
            pendingRequest.abort()
        }
        pendingRequest = null;

        unlockScroll();

        searchApi.then((api) => {
            api.forceCheck();
        })

        $links.map(($link) => removeClass('is-active', $link));
        $links.map(($link) => $link.setAttribute('aria-selected', 'false'));
        $overlay.setAttribute('aria-hidden', 'true');
        removeClass('is-active', $overlay);
        removeClass('is-active', $baseElement)

        isOpen = false;
    }

    function loadSidebar($target, payload = null) {
        if(payload){
            let formData = new URLSearchParams(payload);

            pendingRequest = fetch($target.href,
                {
                    body: formData
                });
        }else{
            pendingRequest = fetch($target.href);
        }

        $links.map(($link) => removeClass('is-active', $link));
        $overlay.setAttribute('aria-hidden', 'false');
        isOpen = true;

        searchApi.then((api) => {
            api.closeSearch();
        })

        lockScroll();

        asyncAppend({
            target: [$container],
            loading: [$container.appendChild($loading)],
        }, pendingRequest).then(() => {
            pendingRequest = null;
            removeLoadingContainer($container);
            $links.map(($link) => $link.setAttribute('aria-selected', 'false'));
            $target.setAttribute('aria-selected', 'true');
            addClass('is-active', $target);
            addClass('is-active', $overlay);
            addClass('is-active', $baseElement);
        }).catch((error) => {
            console.error(error)
            pendingRequest = null;
            closeSidebar();
            removeLoadingContainer($container)
        })
    }

    $baseElement.sidenav = {
        closeSidebar,
        loadSidebar,
        isOpen : () => {
            return isOpen
        }
    }
    trigger(INITIALIZED_EVENT, $baseElement);
}

export function getApi(element) {
    if(element.sidenav) {
        return Promise.resolve(element.sidenav);
    } else {
        return new Promise(function(resolve, reject) {
            function initializeHandler() {
                resolve(element.sidenav);
                off(INITIALIZED_EVENT, initializeHandler, element);
            }
            on(INITIALIZED_EVENT, initializeHandler, element);
        });
    }
}
