'use strict'
import { findAllIn } from "@elements/dom-utils";
import { createHtmlElement } from "./helper"

export function getLoadingContainer(config = {}) {
    return createHtmlElement('<span class="js-loading"><span class="js-loading-container load-container ' +
        (config?.small ? 'load-container--small ' : '') +
        (config?.fixed ? 'load-container--sticky ' : '') +
        '">' +
            '<span class="loader"></span>' +
            '<p class="sr-only">loading</p>' +
        '</span>' +
        (config?.overlay ? '<div class="load-container-bg"></div>' : '') +
        '</span>'
    );
}

export function removeLoadingContainer($scope) {
    findAllIn('.js-loading', $scope).map((loading) => loading.parentNode.removeChild(loading));
}