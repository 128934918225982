'use strict'

export function init() {
    const $navBar = document.querySelector('.js-hide-anchor-nav');
    const $navItems = document.querySelector('.js-anchor-nav__item');
    const $placeholder = document.querySelector('.js-affix__placeholder');
    if($navItems == null || $navItems.length < 1){
        $navBar.classList.add("d-none");
        $placeholder.style.minHeight = "0px";
    }
}