'use strict'

import {onFind} from "@elements/init-modules-in-scope";
import * as sideNav from "./sidenav";
import {findAll, findIn} from "@elements/dom-utils";

export function init() {

    const
        $sideNav        = findAll('.js-sidenav')[0], //can only be one
        $target         = findIn('.js-sidenav__link--contact', $sideNav),
        $contactLink      = document.querySelectorAll('.js-contact-link')[0];

    onFind('.js-contact-link', async function (link) {
        let sideNavInstance = await sideNav.getApi($sideNav);
        $contactLink.addEventListener('click', (e) => {
            e.preventDefault();
            sideNavInstance.loadSidebar($target)
        });
    });
}
